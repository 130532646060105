import React from "react"
import { FooterWithCTA, SEO, Layout, Hero } from "@components"
import Content from "@components/pages/query-examples/Content.js"

const QueryExamplesPage = () => {
  return (
    <>
      <Layout>
        <SEO
          title="92+ Best DynamoDB Query Examples for 2025"
          description="Querying DynamoDB can be confusing -- this page contains 92 examples of DynamoDB queries that will help you kickstart your DDB query writing process."
          canonical="https://dynobase.dev/dynamodb-query-examples/"
        />
        <div
          style={{
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Hero title={"92 DynamoDB Query Examples"} />
        </div>
        <Content />
        <FooterWithCTA />
      </Layout>
    </>
  )
}

export default QueryExamplesPage
